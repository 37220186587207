<template>
  <BaseInputText
    v-bind="$props"
    placeholder="Search"
    @update:model-value="
      (value) => emit('update:modelValue', value)
    "
  >
    <template #after>
      <IconHeroiconsSolidSearch />
    </template>
  </BaseInputText>
</template>

<script setup lang="ts">
// After Vue 3.3 we can import BaseInputTextProps and set
// Props = Omit<BaseInputTextProps, "placeholder">
defineProps<{
  name?: string
  optional?: boolean
  label?: string
  disabled?: boolean
  rules?: Array<(value: string) => boolean | string>
  hint?: string
  modelValue: string
  inputClass?: string
  type?: string
  pattern?: string
}>()

const emit = defineEmits<{
  (event: "update:modelValue", value: string): void
}>()
</script>
