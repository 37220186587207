<template>
  <HeadlessSwitchGroup
    as="div"
    class="flex items-center"
    :class="disabled ? 'opacity-50' : ''"
  >
    <HeadlessSwitch
      :disabled="disabled"
      :model-value="modelValue"
      :class="[
        modelValue ? 'bg-green' : 'bg-background-fg/10',
        'focus:ring-green relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out',
      ]"
      @update:model-value="
        (newValue) => emit('update:modelValue', newValue)
      "
    >
      <span
        aria-hidden="true"
        :class="[
          modelValue ? 'translate-x-5' : 'translate-x-0',
          'bg-primary-fg pointer-events-none inline-block h-5 w-5 transform rounded-full shadow ring-0 transition duration-200 ease-in-out',
        ]"
      />
    </HeadlessSwitch>
    <slot name="label">
      <HeadlessSwitchLabel
        v-if="label"
        as="span"
        class="ml-3"
      >
        <span
          class="text-background-fg/87 text-xs font-medium"
        >
          {{ label }}
        </span>
      </HeadlessSwitchLabel>
    </slot>
  </HeadlessSwitchGroup>
</template>

<script setup lang="ts">
defineProps<{
  modelValue: boolean
  label?: string
  disabled?: boolean
}>()

const emit = defineEmits<{
  (event: "update:modelValue", value: boolean): void
}>()
</script>
